import React from "react"

import Layout from "../components/Layout"
import Icon from "../components/Icon/Icon"
import MasonryLaCasaPlantaBaja from "../components/MasonryLaCasaPlantaBaja/MasonryLaCasaPlantaBaja"
import MasonryLaCasaPrimeraPlanta from "../components/MasonryLaCasaPrimeraPlanta/MasonryLaCasaPrimeraPlanta"
import MasonryLaCasaSegundaPlanta from "../components/MasonryLaCasaSegundaPlanta/MasonryLaCasaSegundaPlanta"

const LaCasa = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <section className="text-component">
          <div className="max-width-sm margin-x-auto">
            <h2 className="text-xxl font-heading font-bold">La Casa</h2>
            <p className="text-sm">
              Casa de nueva construcción, aunando las mayores comodidades con la
              más auténtica tradición manchega en su decoración. La casa dispone
              de:
            </p>
          </div>

          <p className="margin-top-lg text-md font-bold font-heading ">
            Planta baja
          </p>
          <ul className="text-sm list-style-none">
            <li>
              <Icon iconName="icon-car" iconClassName="margin-right-xs" />
              Cochera (3 vehículos)
            </li>
            <li>
              <Icon iconName="icon-kitchen" iconClassName="margin-right-xs" />
              Cocina campera (chimenea, vitroceramica, fregadero,
              frigorifico...)
            </li>
            <li>
              <Icon iconName="icon-pool" iconClassName="margin-right-xs" />
              Patio con Piscina
            </li>
            <li>
              <Icon iconName="icon-bathroom" iconClassName="margin-right-xs" />1
              baño
            </li>
            <li>
              <Icon iconName="icon-bed" iconClassName="margin-right-xs" />1
              dormitorio con baño
            </li>
          </ul>
        </section>
        <div className="margin-top-md margin-bottom-xxxl">
          <MasonryLaCasaPlantaBaja />
        </div>

        <section className="text-component">
          <p className="margin-top-lg text-md font-bold font-heading ">
            Primera planta
          </p>
          <ul className="text-sm  list-style-none">
            <li>
              <Icon iconName="icon-fireplace" iconClassName="margin-right-xs" />
              Salón con chimenea
            </li>
            <li>
              <Icon iconName="icon-bed" iconClassName="margin-right-xs" />4
              dormitorios
            </li>
            <li>
              <Icon iconName="icon-bathroom" iconClassName="margin-right-xs" />3
              baños
            </li>
            <li>
              <Icon iconName="icon-kitchen" iconClassName="margin-right-xs" />
              Cocina
            </li>
            <li>
              <Icon iconName="icon-terrace" iconClassName="margin-right-xs" />
              Terraza al patio y balcón a la calle
            </li>
          </ul>
        </section>
        <div className="margin-top-md margin-bottom-xxxl">
          <MasonryLaCasaPrimeraPlanta />
        </div>

        <section className="text-component">
          <p className="margin-top-lg text-md font-bold font-heading ">
            Segunda planta (Buhardilla)
          </p>
          <ul className="text-sm list-style-none">
            <li>
              <Icon iconName="icon-room" iconClassName="margin-right-xs" />
              Salón con cocina americana
            </li>
            <li>
              <Icon iconName="icon-bed" iconClassName="margin-right-xs" />3
              dormitorios
            </li>
            <li>
              <Icon iconName="icon-bathroom" iconClassName="margin-right-xs" />2
              baños
            </li>
            <li>
              <Icon iconName="icon-terrace" iconClassName="margin-right-xs" />
              Terraza
            </li>
          </ul>
        </section>
        <div className="margin-y-md">
          <MasonryLaCasaSegundaPlanta />
        </div>
      </div>
    </Layout>
  )
}

export default LaCasa
