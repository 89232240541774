import React from "react"
import { withPrefix, StaticQuery, graphql } from "gatsby"

class MasonryLaCasaPlantaBaja extends React.Component {
  componentDidMount() {
    let masonry = document.createElement("script")
    masonry.src = `${withPrefix("scripts/masonry-script.js")}`
    masonry.id = "masonry-script-js"
    document.body.appendChild(masonry)

    let imageZoom = document.createElement("script")
    imageZoom.src = `${withPrefix("scripts/image-zoom-script.js")}`
    imageZoom.id = "image-zoom-script-js"
    document.body.appendChild(imageZoom)
  }

  componentWillUnmount() {
    document.getElementById("masonry-script-js").remove()
    document.getElementById("image-zoom-script-js").remove()
    document.querySelectorAll(".js-image-zoom__lightbox").forEach(function (a) {
      a.remove()
    })
  }

  render() {
    const { data } = this.props

    return (
      <section className="masonry js-masonry">
        <div className="masonry__loader" aria-hidden="true">
          <svg className="icon icon--md icon--is-spinning" viewBox="0 0 32 32">
            <g
              strokeLinecap="square"
              strokeLinejoin="miter"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
            >
              <circle cx="16" cy="16" r="15" opacity="0.4"></circle>
              <path d="M16,1A15,15,0,0,1,31,16" strokeLinecap="butt"></path>
            </g>
          </svg>
        </div>

        <ul className="masonry__list js-masonry__list">
          {data.plantaBaja.nodes.map(data => (
            <li className="masonry__item js-masonry__item" key={data.id}>
              <figure className="image-zoom js-image-zoom" data-morph="off">
                <img
                  src={data.childImageSharp.fluid.src}
                  className="block width-100% image-zoom__preview js-image-zoom__preview"
                  alt=""
                />
              </figure>
            </li>
          ))}
        </ul>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        plantaBaja: allFile(
          filter: { relativePath: { regex: "/(la-casa/casa1-planta-baja)/" } }
        ) {
          nodes {
            id
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    `}
    render={data => <MasonryLaCasaPlantaBaja data={data} {...props} />}
  />
)
